import { makeStyles, Container, Typography, Box, Link } from "@material-ui/core"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout/layout-home"
import TitledContentWrapper from "../components/layout/titled-content-wrapper"
import ReactMarkdown from "react-markdown"
import { EmbedVideo } from "../components/utils/embed-video"

const useStyles = makeStyles(theme => ({
  button: {
    boxShadow: "none",
    textDecoration: "none !important",
    color: "#010000",
    fontSize: "16px",
    textTransform: "uppercase",
    fontWeight: "700",
    paddingLeft: "22.5px",
    paddingRight: "22.5px",
    paddingTop: "15.5px",
    paddingBottom: "15.5px",
    backgroundColor: "#F26522",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#FF6a24",
    },
    "&:active": {
      boxShadow: "none",
    },
  },
}))

const WorkshopsPage = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        workShopPage {
          WorkShopTitle
          WorkShop {
            WorkshopDescripiton
            WorkshopVideoTitle
            WorkshopVidoLink
            WorkshopButtonTitle
            WorkshopButton
            ApplyLink
          }
        }
      }
    }
  `)

  const classes = useStyles()

  return (
    <Layout>
      <TitledContentWrapper title={data.strapi.workShopPage.WorkShopTitle}>
        <Container>
          {data.strapi.workShopPage.WorkShop.map(
            ({
              WorkshopDescripiton,
              WorkshopVideoTitle,
              WorkshopVidoLink,
              WorkshopButtonTitle,
              WorkshopButton,
              ApplyLink,
            }) => (
              <Box style={{ marginBottom: "100px" }}>
                <Typography style={{ paddingBottom: "16px" }} variant="body1">
                  <ReactMarkdown source={WorkshopDescripiton} />
                </Typography>
                <Typography style={{ paddingBottom: "16px" }} variant="h3">
                  {WorkshopVideoTitle}
                </Typography>
                <Box>
                  <EmbedVideo src={WorkshopVidoLink} />
                </Box>
                <Typography
                  style={{
                    paddingBottom: "16px",
                    color: "#F26522",
                    marginTop: "50px",
                  }}
                  variant="h3"
                >
                  {WorkshopButtonTitle}
                </Typography>
                <Box style={{ marginTop: "30px", marginBottom: "50px" }}>
                  <Link
                    href={ApplyLink}
                    variant="contained"
                    className={classes.button}
                  >
                    {WorkshopButton}
                  </Link>
                </Box>
              </Box>
            )
          )}
        </Container>
      </TitledContentWrapper>
    </Layout>
  )
}

export default WorkshopsPage
