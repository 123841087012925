import { makeStyles } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles({
  iframeContainer: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    aspectRatio: "16/9",
    maxWidth: "1440px",
    maxHeight: "900px",
    margin: "16px 0",
  },
  iframeResponsive: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    maxWidth: "1440px",
    maxHeight: "751px",
  },
})

export const EmbedVideo = ({ src }) => {
  const classes = useStyles()

  return (
    <div className={classes.iframeContainer}>
      <iframe
        className={classes.iframeResponsive}
        src={src}
        title={src}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  )
}
